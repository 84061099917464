<template>
  <CardGray header="Brew Table" v-model="search" placeholder="search (tank, name)">
    <Table class="table-hover" v-if="brews.length">
      <template v-slot:headRow>
        <TH></TH>
        <TH>Action</TH>
        <THSort :sort="state.state" @click="sortNum('state')">State</THSort>
        <THSort :sort="state.recipeName" @click="sortStr('recipeName')"> Name</THSort>
        <THSort :sort="state.tankName" @click="sortStr('tankName')"> Tank</THSort>
        <THSort :sort="state.quantity" @click="sortNum('quantity')"> Size</THSort>
        <THSort :sort="state.start" @click="sortNum('start')">Start</THSort>
        <THSort :sort="state.end" @click="sortNum('end')">End</THSort>
      </template>
      <template v-slot:body>
        <TR v-for="(item, index) in searched" :key="index">
          <TD>
            <ButtonGroup>
              <Button @click="openBrew(item.id)">
                <BIconPencilSquare />
              </Button>
              <ButtonLink name="BrewDetail" :params="item.id">
                <BIconEye />
              </ButtonLink>
              <Button @click="openDeleteBrew(item.id)">
                <BIconTrash />
              </Button>
            </ButtonGroup>
          </TD>
          <TD>
            <ButtonLink v-if="item.state == BREW.PLANNING" name="BrewSheet" :params="item.id">
              Brew sheet
            </ButtonLink>
            <ButtonLink
              v-else-if="item.state == BREW.FERMENTING"
              name="FermentationSheet"
              :params="item.id"
            >
              Fermentation sheet
            </ButtonLink>
            <ButtonLink v-else-if="item.state == BREW.DONE" name="TapSheet" :params="item.id">
              Tap sheet
            </ButtonLink>
            <div v-else>-</div>
          </TD>
          <TD><BrewState :brew="item" style="width:120px"/></TD>
          <TD>{{ item.formatCount }} {{ item.recipeName }}</TD>
          <TD>{{ item.tankName }}</TD>
          <TD>{{ item.recipeCopy.batchSize }} / {{ item.tankSize }} {{ item.tankUnit }} </TD>
          <!-- <TD> <BTime :format="0" :time="item.start"/></TD> -->
          <!-- <TD> <BTime :format="0" :time="item.end"/></TD> -->
        </TR>
      </template>
    </Table>
  </CardGray>
</template>

<script>
import comp from "@/components/base/"
import icons from "../../assets/icons"

import { mapActions, mapState } from "vuex"
import { BREW } from "../../services/enums"
import Fuse from "fuse.js"
// import cloneDeep from "clone-deep"
// import { }
import CardGray from "../base/CardGray.vue"

export default {
  name: "BrewTable",
  props: ["brews"],
  components: {
    ...comp,
    ...icons,
    CardGray,
  },
  setup() {
    return {
      BREW,
    }
  },
  data() {
    return {
      search: "",
      state: {},
    }
  },
  computed: {
    ...mapState(["tanks"]),
    fuse() {
      return new Fuse(this.brews, { keys: ["tankName", "recipeName"] })
    },
    searched() {
      return this.search ? this.fuse.search(this.search).map(item => item.item) : this.brews
    },
  },
  methods: {
    ...mapActions(["openBrew"]),
    ...mapActions(["openDeleteBrew"]),
    sortNum(key) {
      if (this.state[key]) this.searched.sort((a, b) => a[key] - b[key])
      else this.searched.sort((a, b) => b[key] - a[key])
      this.state[key] = !this.state[key]
    },
    sortStr(key) {
      this.searched.sort((a, b) => {
        let val = String(a[key]).localeCompare(b[key])
        return this.state[key] ? val : val * -1
      })
      this.state[key] = !this.state[key]
    },
  },
}
</script>
